@font-face {
  font-family: 'Kanit';
  src: local('Kanit'),
   url('./fonts/Kanit/Kanit-Regular.ttf') format('truetype'),
   url('./fonts/Kanit/Kanit-Bold.ttf') format('truetype'),
   url('./fonts/Kanit/Kanit-SemiBold.ttf') format('truetype'),
   url('./fonts/Kanit/Kanit-Italic.ttf') format('truetype'),
   url('./fonts/Kanit/Kanit-Light.ttf') format('truetype'),
   url('./fonts/Kanit/Kanit-Medium.ttf') format('truetype');
 }

body {
  margin: 0;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Kanit', sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
