html {
  --color-white: #FFF;
  --color-grey: #A5A5A5;
  --color-light-grey: #DDDDDD;
  --color-lighter-grey: #ECECEC;
  --color-bg-header: #FFF;
  --color-bg-footer: #29384D;
  --color-highlight: #FCAA18;
  --color-main-text: #29384D;
  --color-text-lv2: #4E99FE;
  --color-success: #44BF4C;

  --font-page-header: 22px;
  --font-header: 18px;
  --font-title: 16px;
  --font-info: 14px;

  --size-header: 70px;
  --size-page-space: 20px;
  --size-section-header: 300px;
  --size-header-title: 35px;
  --size-section-padding: 20px;

  --default-radius: 10px;
  --main-card-radius: 30px;

  --size-footer-contact-section: 80px;

  font-size: var(--font-info);
  color: var(--color-main-text);
  background-color: var(--color-white);
  font-family: 'Kanit';
}

html,
body,
#root {
  height: 100%;
  background-color: var(--color-white);
}

.section-content {
  min-height: 100vh;
  background-color: var(--color-white);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--color-white);
}

.App-link {
  color: var(--color-text-lv2);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*********************************
* override lib 
**********************************/
.ant-layout,
[class*=" ant-form"],
.ant-table-wrapper .ant-table {
  font-family: 'Kanit';
}

.ant-spin-nested-loading {
  background-color: var(--color-white);
}

.ant-card .ant-card-actions {
  border-top: 0;
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background: transparent;
}

/*********************************
* global 
**********************************/
.flex {
  display: flex;
}

.gap-15 {
  gap: 15px;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.m-top-20 {
  margin-top: 20px;
}
.m-bottom-20 {
  margin-bottom: 20px;;
}

.m-top-15 {
  margin-top: 15px;
}
.m-bottom-15 {
  margin-bottom: 15px;;
}

.m-left-15 {
  margin-left: 15px;
}

.height-auto {
  height: auto;
}

.info-text {
  font-size: var(--font-info);
}

.center {
  text-align: center;
}

.default-button {
  border-radius: 20px;
  font-weight: bold;
  font-family: 'Kanit';
}

.large-button {
  height: 40px;
  font-size: var(--font-header);
  padding: 0px 30px;
}

.green-button {
  background-color: var(--color-success);
  color: var(--color-white);
}

.yellow-button {
  background-color: var(--color-highlight);
  color: var(--color-white);
}

.blue-button {
  background-color: var(--color-text-lv2);
  color: var(--color-white);
}

.text-highlight {
  color: var(--color-highlight);
}

.text-hover {
  color: var(--color-text-lv2);
}

/*********************************
* main component 
**********************************/
/* breadcrumb */
.breadcrumb {
  margin: 16px 0;
  font-family: 'Kanit';
}

/* header */
.default-header {
  font-family: 'Kanit';
  background: var(--color-bg-header);
  padding: 0;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  -webkit-box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);

  height: var(--size-header);
  padding: 0 var(--size-page-space);
  display: flex;
  align-items: center;

  display: flex;
  justify-content: space-between;
}

.default-header a {
  color: var(--color-main-text);
}
.default-header a:hover {
  color: var(--color-text-lv2);
}

.header-logo {
  width: auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-logo:hover {
  cursor: pointer;
}

/* section header */
.section-header {
  height: var(--size-section-header);
  margin-top: var(--size-header);
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: var(--size-section-padding); */
  overflow: scroll;
  position: relative;
}

.section-header-bg {
  height: var(--size-section-header);
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.section-header.info {
  position: relative;
  text-align: center;
}
.section-header.info .section-header-bg {
  width: 100%;
  left: 0;
}

.section-header .landing {
  z-index: 1;
}

.section-header-title {
  font-size: var(--size-header-title);
  font-weight: bold;
}

.section-header .landing,
.section-header-container {
  color: var(--color-main-text);
  text-shadow: 3px 2px 4px var(--color-white);
}

.section-header-container {
  position: absolute;
  z-index: 1;
  top: var(--size-section-padding);
  left: var(--size-section-padding);
  right: var(--size-section-padding);
  /* height: var(--size-section-header); */
  overflow: auto;
}
.section-header-container .section-header-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.section-header-description {
  font-size: var(--font-header);
}

.section-header-optional {
  font-size: var(--font-info);
  font-style: italic;
}

/* footer */
.ant-layout-footer.default-footer {
  padding: 15px var(--size-page-space);
}
.default-footer {
  width: 100%;
  background-color: var(--color-bg-footer);
  text-align: center;
  /* position: fixed;
  left: 0;
  bottom: 0; */
  font-family: 'Kanit';
  color: var(--color-white);
  font-size: var(--font-title);
  margin-top: auto;
}
.container-flex {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  height: var(--size-footer-contact-section);
}
.qr-code {
  width: var(--size-footer-contact-section);
  height: var(--size-footer-contact-section);
}

.contact {
  display: flex;
  flex-wrap: wrap;
}
.contact.social {
  width: var(--size-footer-contact-section);
}
.contact > .contact-item {
  flex: 50%; /* or - flex: 0 50% - or - flex-basis: 50% - */
  padding: 3px 0;
  /* border: 1px solid #fff; */
}
.contact-item .contact-img {
  width: 30px;
  height: 30px;
}

.contact.location {
  width: 270px;
}
.contact.location > .contact-item {
  text-align: left;
  font-size: var(--font-info);
  padding: 5px 0;
}
.contact-text {
  padding-left: 5px;
}

.full-divider {
  background-color: var(--color-white);
  height: 100%;
  width: 2px;
}

@media only screen and (max-width: 700px) {
  .ant-layout-footer.default-footer {
    padding: 5px;
    height: 205px;
  }
  .container-flex {
    gap: 0;
  }

  .contact.social {
    width: 91px;
  }
  .contact > .contact-item {
    flex: 0 1;
    padding: 3px;
  }

  .contact.location > .contact-item {
    flex: 50% 1;
    font-size: 12px;
  }
}

/*********************************
* review section
**********************************/
#review-section {
  padding-left: var(--size-page-space);
  padding-right: var(--size-page-space);
  text-align: center;
}

.review-item {
  text-align: center;
  padding-top: 15px;
}

.ant-card .ant-card-cover .review-image {
  background-color: #EFAC42;
  width: var(--size-footer-contact-section);
  height: var(--size-footer-contact-section);
  margin: 0 auto;
  border-radius: 50%;
}

#review-section .ant-card .ant-card-meta-detail {
  display: flex;
  flex-direction: column-reverse;
}
#review-section .ant-card-meta-title {
  margin-top: 8px;
  margin-bottom: 0;
}
#review-section .ant-card-body {
  padding-bottom: 15px;
}

/*********************************
* customer page
**********************************/
.site-layout {
  padding: 0 var(--size-page-space);
  background-color: var(--color-white);
}

#reserve-page.site-layout {
  margin-top: var(--size-header);
}

.page-header {
  font-family: 'Kanit';
  color: var(--color-main-text);
  font-size: var(--font-page-header);
  text-align: center;
  border-bottom: 5px solid var(--color-highlight);
}

.content-bg {
  padding: var(--size-section-padding) 0;
  background: #FFF;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 0;
  justify-content: space-between;
}
.content-bg::after {
  content: "";
  flex: 0 1 100%;
}

#class-page .content-bg {
  padding: 0;
}

#class-page .ant-table-cell {
  text-align: center;
  border: 0;
  font-weight: bold;
}
#class-page th.ant-table-cell {
  color: var(--color-white);
  border-radius: 15px 15px 0 0;
  padding: 5px 15px;
  font-size: var(--font-header);
}
#class-page th.ant-table-cell:nth-child(even) {
  background-color: var(--color-highlight);
}
#class-page th.ant-table-cell:nth-child(odd) {
  background-color: var(--color-text-lv2);
}

.ant-card.card-item .ant-card-cover img.main-card-cover {
  padding: 15px 15px 0;
  border-radius: var(--main-card-radius);
}
.card-item {
  width: 100%;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: var(--main-card-radius);
  font-family: 'Kanit';
}
@media only screen and (min-width: 700px) {
  .card-item {
    width: 45%;
  }
  .content-bg::after {
    content: "";
    flex: 0 1 45%;
  }
}
@media only screen and (min-width: 1024px) {
  .card-item {
    width: 30%;
  }
  .content-bg::after {
    content: "";
    flex: 0 1 30%;
  }
}

.form-container {
  /* max-width: 600px; */
}
.form-item {
  text-align: 'end';
}

.form-section-header {
  background-color: var(--color-text-lv2);
  text-align: center;
  font-family: 'Kanit';
  font-size: var(--font-header);
  color: var(--color-white);
  border-top-left-radius: var(--default-radius);
  border-top-right-radius: var(--default-radius);
  padding: 5px 0;
}
.form-section-header.section-info {
  background-color: var(--color-highlight);
}
.form-section-header.section-additional {
  background-color: var(--color-text-lv2);
}

.form-section-header.section-payment {
  background-color: var(--color-success);
}

.form-section {
  background-color: var(--color-white);
  padding: 30px 30px 5px 30px;
  border-bottom-left-radius: var(--default-radius);
  border-bottom-right-radius: var(--default-radius);
  -webkit-box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.1);
}

.form-section .ant-form-item-label>label {
  color: var(--color-main-text);
}

#course-page .ant-card .ant-card-actions>li:not(:last-child) {
  border-color: var(--color-highlight);
}

/*********************************
* admin page
**********************************/
#admin-page {
  background-color: var(--color-white);
}

#admin-page .site-layout {
  margin-top: calc(var(--size-header) + 15px);
}

.input-element {
  display: flex;
  gap: 5;
  height: 100%;
  align-items: center;
}

.input-field {
  width: 200px;
}

.picker-field {
  width: 300px;
}

.row-items {
  display: flex;
  column-gap: 10px;
}

/*********************************
* login admin page
**********************************/

.signin-box{
  position: relative;
}

.signin-input{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 25%);
  width: 300px;
  height: 400px;
  padding: 30px 50px 20px;
  background-color: var(--color-lighter-grey);
  border-radius: 8px;
}

.signin-field{
  position: relative;
}

.text-input{
  height: 40px;
  width: 100%;
  font-size: 12px;
  padding-top: 10px;
  padding-left: 10px;
  border: none;
  outline: none;
  border-radius: 4px;
  color: var(--color-main-text);
  background-color: var(--color-white);
}

.placeholder{
  position: absolute;
  color: var(--color-grey);
  top: 10px;
  left: 5%;
  pointer-events: none;
}

.text-input:focus{
  background-color: var(--color-light-grey);
}

.text-input:focus ~ .placeholder,
.text-input:valid ~ .placeholder{
    top: 7%;
    font-size: 10px;
    transition: 0.2s;
}

.btnSignin{
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: var(--color-white);
  background-color: var(--color-highlight);
  margin-bottom: 20px;
  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btnSignin:hover{
  background-color: var(--color-text-lv2);
}
